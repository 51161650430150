import React, { FC, ReactElement, useState } from 'react'
import "./Modal.css"

interface Props {
    children: ReactElement;
    header?: string;
    buttons?: ('' | 'ok' | 'cancel')[];
    onConfirm?: () => void
}
export function showConfirmDialog(
    header: string,
    buttons?: ['ok' | 'cancel'],
) {
    console.log('tests')
    return (
        <div className="modal">
            <div className="overlay"></div>
            <div className="modal-container">
                <div className="modal-content">
                    {header}
                </div>
            </div>
        </div>
        // <div className="modal">
        //     {header}
        //     {/* <div className="overlay"></div>
        //     <div className="modal-container">
        //         <div className="modal-header">
        //             {header && <div>{header}</div>}
        //             <button className='close-modal' onClick={toggleModal} >x</button>
        //         </div>
        //         <div className="modal-content">
        //             {children}
        //         </div>
        //         <div className="modal-footer">
        //             {buttons &&
        //                 <>
        //                     {buttons.includes('cancel') &&
        //                         <button
        //                             className='modal-btn-cancel'
        //                             onClick={toggleModal}
        //                         >Zruš</button>
        //                     }
        //                     {buttons.includes('ok') &&
        //                         <button
        //                             className='modal-btn-ok'
        //                             onClick={toggleconfirm}
        //                         >OK</button>
        //                     }
        //                 </>
        //             }
        //         </div>
        //     </div> */}
        // </div>
    )
}

export default function ModalDialog({
    children,
    header = '',
    buttons = ['ok'],
    onConfirm = () => { }
}: Props,): ReturnType<FC> {

    const [btnText, setBtnText] = useState<string>('SaveAs')
    const [modal, setModal] = useState<boolean>(false)

    const toggleModal = () => {
        setModal(!modal)
        setBtnText('SaveAs')
    }

    const toggleconfirm = async () => {
        onConfirm()
        setModal(!modal)
        setBtnText('saved')
        setTimeout(() => {
            setBtnText('SaveAs');
        }, 1000);

    }

    if (modal) {
        document.body.classList.add('active-model')
    } else {
        document.body.classList.remove('active-model')
    }

    return (
        <>
            <button
                onClick={toggleModal}
                className='btn-modal'
            >
                {btnText}
            </button>

            {modal &&
                <div className="modal">
                    <div className="overlay"></div>
                    <div className="modal-container">
                        <div className="modal-header">
                            {header && <div>{header}</div>}
                            <button className='close-modal' onClick={toggleModal} >x</button>
                        </div>
                        <div className="modal-content">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {buttons &&
                                <>
                                    {buttons.includes('cancel') &&
                                        <button
                                            className='modal-btn-cancel'
                                            onClick={toggleModal}
                                        >Zruš</button>
                                    }
                                    {buttons.includes('ok') &&
                                        <button
                                            className='modal-btn-ok'
                                            onClick={toggleconfirm}
                                        >OK</button>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export function KalendarModalDialog({
    children,
    header = '',
    buttons = ['ok'],
    onConfirm = () => { }
}: Props,): ReturnType<FC> {

    const [btnText, setBtnText] = useState<string>('SaveAs')
    const [modal, setModal] = useState<boolean>(false)

    const toggleModal = () => {
        setModal(!modal)
        setBtnText('SaveAs')
    }

    const toggleconfirm = async () => {
        onConfirm()
        setModal(!modal)
        setBtnText('saved')
        setTimeout(() => {
            setBtnText('SaveAs');
        }, 1000);

    }

    if (modal) {
        document.body.classList.add('active-model')
    } else {
        document.body.classList.remove('active-model')
    }

    return (
        <>
            <button
                onClick={toggleModal}
                className='btn-modal'
            >
                {btnText}
            </button>

            {modal &&
                <div className="modal">
                    <div className="overlay"></div>
                    <div className="modal-container">
                        <div className="modal-header">
                            {header && <div>{header}</div>}
                            <button className='close-modal' onClick={toggleModal} >x</button>
                        </div>
                        <div className="modal-content">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {buttons &&
                                <>
                                    {buttons.includes('cancel') &&
                                        <button
                                            className='modal-btn-cancel'
                                            onClick={toggleModal}
                                        >Zruš</button>
                                    }
                                    {buttons.includes('ok') &&
                                        <button
                                            className='modal-btn-ok'
                                            onClick={toggleconfirm}
                                        >OK</button>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
