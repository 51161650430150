import React, { useEffect, useState } from 'react';

export const sviatky: { [mesiac: number]: number[] } = {
    1: [1, 6],
    2: [],
    3: [],
    4: [],
    5: [1, 8],
    6: [],
    7: [6],
    8: [29],
    9: [15],
    10: [],
    11: [1, 17],
    12: [24, 25, 26],
}

export type Event = {
    id: string,
    typ: string,
    opakovanie: 'rok' | 'mesiac' | 'tyzden' | 'den' | 'raz',
    zaciatok: Date | null,
    koniec: Date | null,
    datum: Date,
    nazov: string,
    poznamka: string,
}

export function formatDate(date: Date, format: string): string {
    const fd = date.getDate().toString().padStart(2, '0');
    const fm = date.getMonth().toString().padStart(2, '0');
    const fY = date.getFullYear().toString();
    const fH = date.getHours().toString().padStart(2, '0');
    const fi = date.getMinutes().toString().padStart(2, '0');
    const fs = date.getSeconds().toString().padStart(2, '0');

    return format.replace('d', fd).replace('m', fm).replace('Y', fY).replace('H', fH).replace('i', fi).replace('s', fs)
}

export const HGap: React.FC<{ width?: number }> = ({ width = 10 }) => {
    return <div style={{ display: 'inline-block', width: width }}></div>
}
export const VGap: React.FC<{ height?: number }> = ({ height = 10 }) => {
    return <div style={{ display: 'inline-block', height: height }}></div>
}