"use client"
// components/Textarea.tsx
import React, { useEffect, useState } from 'react';
import '../App.css'
import { Event, formatDate, HGap, sviatky, VGap } from './kalendar_func';
import { KalendarModalDialog } from './Modal/Modal';

interface MesiacProps {
    events: Event[];
}

// const Kalendar: React.FC = () => {
function Kalendar(props: { handleSetApp: (app: string) => void }) {

    const [events, setEvents] = React.useState<Event[]>([]);
    const [nadpis, setNadpis] = useState<string>('');
    const [selectDay, setSelectDay] = React.useState<Date>(new Date);
    const [den, setDen] = React.useState<number>(selectDay.getDate());
    const [mesiac, setMesiac] = React.useState<number>(selectDay.getMonth() + 1);
    const [rok, setRok] = React.useState<number>(selectDay.getFullYear());
    const [zobrazenie, setZobrazenie] = React.useState<string>('mesiac');

    const saveData = (async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'pragma': 'no-cache', 'cache-control': 'no-cache' },
            body: JSON.stringify({ akcia: 'save', data: events }),
        };
        try {
            const response = await fetch('kalendar.php', requestOptions).then(response => response.json());
            setNadpis(response.status);
        } catch (error) {
            setNadpis('Nepodarilo sa uložiť obsah.')
        }
    })

    useEffect(() => {
        const loadData = (async () => {
            const requestOptions = {
                method: 'POST',
                headers: { 'pragma': 'no-cache', 'cache-control': 'no-cache', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', },
                body: JSON.stringify({ akcia: 'loadBaseData', data: 'kalendar.json' }),
                // mode: 'cors',
                // referrerPolicy: 'no-referrer',
                // cache: 'no-cache',
            };
            try {
                console.log('test')
                // const response = await fetch('kalendar.php', requestOptions).then(response => response.json());
                const response = await fetch('../../public/kalendar.php', requestOptions).then(response => response.json());
                console.log(response)
                const events: Event[] = []
                for (const event of response.jsonData) {
                    events.push(event)
                }
                setEvents(events);
            } catch (error) {
                setNadpis('Nepodarilo sa nahrat obsah.')
            }
        })
        loadData()
    }, [])

    return <>
        <div>{nadpis}</div>
        <div>{zobrazenie}</div>
        {zobrazenie == 'mesiac' &&
            <div style={{ padding: 10, width: 600 }}>
                <div style={{ padding: 'auto' }}>
                    <Mesiac
                        events={events}
                    />
                </div>
                <button onClick={() => props.handleSetApp('poznamky')}>Poznámky</button>
            </div>
        }
    </>
}

const Mesiac: React.FC<MesiacProps> = ({ events }) => {
    const [defaultDay, setDefaultDay] = React.useState<Date>(new Date);
    const [selectedDay, setSelectedDay] = React.useState<Date>(new Date())
    const [mesiac, setMesiac] = React.useState<number>(defaultDay.getMonth() + 1);
    const [rok, setRok] = React.useState<number>(defaultDay.getFullYear());
    const daysInMonth = new Date(rok, mesiac, 0).getDate();
    const firstDayOfMonth = new Date(rok, mesiac - 1, 0).getDay();
    const days: number[] = [];

    for (let i = 1; i <= daysInMonth; i++) {
        days.push(i);
    }

    const today = new Date()

    const handleDayClick = (day: Date) => {
        setSelectedDay(day)
    }

    const handleMonthClick = (month: number) => {
        if (month == 0) {
            setMesiac(12)
            setRok(rok - 1)
        } else if (month == 13) {
            setMesiac(1)
            setRok(rok + 1)
        } else {
            setMesiac(month)
        }
    }

    const handleOtherMountDayClick = (day: Date) => {
        setMesiac(day.getMonth() + 1)
        setSelectedDay(day)
    }

    return (
        <div>
            <div style={{ paddingBottom: 20 }}>
                <div style={{ display: 'inline-block', width: '24%', textAlign: 'right', verticalAlign: 'bottom' }}>
                    <button style={{ width: 100, height: 70 }} onClick={() => handleMonthClick(mesiac - 1)} > ← </button>
                </div>
                <div style={{ display: 'inline-block', width: '50%', textAlign: 'center' }}>
                    <h2>{mesiac}/{rok}</h2>
                    <button
                        style={{ width: 120, height: 30 }}
                        onClick={() => {
                            setMesiac(today.getMonth() + 1)
                            setSelectedDay(today)
                            setDefaultDay(today)
                        }}
                    >
                        Dnes {today.toLocaleDateString()}
                    </button>
                    <HGap />
                    {formatDate(today, 'dmY') != formatDate(selectedDay, 'dmY') &&
                        <button
                            style={{ width: 120, height: 30 }}
                            onClick={() => {
                                setMesiac(selectedDay.getMonth() + 1)
                                setSelectedDay(selectedDay)
                                setDefaultDay(selectedDay)
                            }}
                        >
                            Ozn. {selectedDay.toLocaleDateString()}
                        </button>
                    }
                </div>
                <div style={{ display: 'inline-block', width: '24%', verticalAlign: 'bottom' }}>
                    <button style={{ width: 100, height: 70 }} onClick={() => handleMonthClick(mesiac + 1)} > → </button>
                </div>
            </div>
            <VGap height={5

            } />
            <KalendarModalDialog
                header='Ukladanie do nového súboru'
                buttons={['ok', 'cancel']}
            >
                <div><input type='text' placeholder='nazov' onChange={(e) => { }} /></div>
            </KalendarModalDialog>

            <table>
                <thead>
                    <tr>
                        <th>Po</th>
                        <th>Ut</th>
                        <th>St</th>
                        <th>Št</th>
                        <th>Pi</th>
                        <th>So</th>
                        <th>Ne</th>
                    </tr>
                </thead>
                <tbody>
                    {Array(Math.ceil((daysInMonth + firstDayOfMonth) / 7))
                        .fill(null)
                        .map((_, weekIndex) => (
                            <tr key={weekIndex}>
                                {Array(7)
                                    .fill(null)
                                    .map((_, dayIndex) => {
                                        const day = weekIndex * 7 + dayIndex + 1 - firstDayOfMonth;
                                        const touchedDay = new Date(rok, mesiac - 1, day);
                                        const touchedDayIsVikend = touchedDay.getDay() == 6 || touchedDay.getDay() == 0
                                        const classOfDay: string[] = ['den'];

                                        if (mesiac - 1 == touchedDay.getMonth()) {
                                            if (touchedDayIsVikend)
                                                classOfDay.push('vikend')
                                            for (const sviatokden of sviatky[mesiac]) {
                                                if (touchedDay.getDate() == sviatokden)
                                                    classOfDay.push('sviatok')
                                            }
                                            if (formatDate(selectedDay, 'dmY') == formatDate(touchedDay, 'dmY'))
                                                classOfDay.push('vybranyDen')
                                        }
                                        const denTentoMesiac = day >= 1 && day <= daysInMonth
                                        return (
                                            <td
                                                key={dayIndex}
                                            >
                                                <Den
                                                    day={denTentoMesiac ? day : touchedDay.getDate()}
                                                    touchedDay={touchedDay}
                                                    events={events}
                                                    handleDayClick={() => denTentoMesiac ? handleDayClick(touchedDay) : handleOtherMountDayClick(touchedDay)}
                                                    options={{
                                                        classOfDay: classOfDay.join(' '),
                                                        style: denTentoMesiac ? undefined : { color: '#ccc' }
                                                    }}
                                                />
                                            </td>
                                        );
                                    })}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

function Den(props: {
    day: number,
    touchedDay: Date,
    handleDayClick: () => void,
    options: { classOfDay?: string, style?: React.CSSProperties }
    events: Event[]
}) {
    const { day, touchedDay, events, handleDayClick, options } = props;
    const mesiac = touchedDay.getMonth() + 1

    const events4day = React.useMemo<{ [id: string]: Event }[]>(() => {
        const temp: Event[] = events.filter(eve =>
            eve.datum.getUTCDate() == touchedDay.getUTCDate()
            || (
                ((eve.opakovanie == 'mesiac' || eve.opakovanie == 'tyzden') && eve.datum.getDay() == touchedDay.getDay())
                && eve.zaciatok && eve.zaciatok.getMonth() + 1 <= mesiac
                && eve.koniec && eve.koniec.getMonth() + 1 >= mesiac
            )
            || eve.opakovanie == 'den'
        )
        const res: { [id: string]: Event }[] = []
        for (const ev of temp) {
            res.push({ [ev.id]: ev });
        }

        return res
    }, [events])

    return <div
        onClick={handleDayClick}
        className={options.classOfDay}
        style={options.style}
    // onContextMenu={(e) => {
    //     e.preventDefault()
    //     return showConfirmDialog('test');
    // }}
    >
        {day}
    </div>

}
export default Kalendar;