import React from 'react';
import Poznamky from './components/Poznamky';
import Kalendar from './components/Kalendar';

const Page: React.FC = () => {
  const [app, setApp] = React.useState<string>('poznamky')

  const handleSetApp = (app: string) => {
    setApp(app)
  }

  return (
    <>
      {app == 'poznamky' &&
        <div className='app_body'><Poznamky handleSetApp={handleSetApp} /></div>
      }
      {app == 'kalendar' &&
        <div className='app_body'><Kalendar handleSetApp={handleSetApp} /></div>
      }
    </>
  );
};

export default Page;